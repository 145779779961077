<template>
  <div class="container">
    <div class="row">
      <div class="col-12 px-0">
        <div class="card mb-1">
          <div class="card-body">
            <dx-util-validation-group>
              <div class="row">
                <div class="col-sm-12 col-md-2">
                  <dx-util-text-box v-model="roleForm.roleName" placeholder="Role Name">
                    <dx-util-validator>
                      <dx-util-required-rule message="Role name is required" />
                    </dx-util-validator>
                  </dx-util-text-box>
                </div>
                <div class="col-sm-12 col-md-6">
                  <dx-util-text-box v-model="roleForm.description" placeholder="Description">
                    <dx-util-validator>
                      <dx-util-required-rule message="Description is required" />
                    </dx-util-validator>
                  </dx-util-text-box>
                </div>
                <div class="col-sm-6 col-md-2">
                  <dx-util-button type="success" icon="add" text="Create" @click="createRole" />
                </div>
                <div class="col-sm-6 col-md-2 text-md-right">
                  <dx-util-button icon="refresh" type="success" @click="getRolesList" />
                </div>
              </div>
            </dx-util-validation-group>
          </div>
        </div>
        <dx-data-grid
          :height="gridHeight"
          :data-source="roles"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :row-alternation-enabled="false"
          :hover-state-enabled="true"
          :customize-columns="customizeColumns"
          @initialized="onInitialized"
          @content-ready="onContentReady"
        >
          <dx-column data-field="roleName" width="18%" />
          <dx-column data-field="description" />
          <dx-column data-field="id" caption="Permissions" alignment="center" cell-template="itemActions" :width="140" :allow-sorting="false" />
          <template #itemActions="{data}">
            <div>
              <dx-util-button type="success" text="Permissions" class="mr-half" hint="Edit Role Permissions" @click="onClickPermissions(data.data)" />
            </div>
          </template>
        </dx-data-grid>
      </div>
    </div>
  </div>
</template>

<script>
import GridBase from '@core/dev-extreme/mixins/grid/base'
import userRoleService from '@/http/requests/system/userRoleService'

export default {
  mixins: [GridBase],
  data() {
    return {
      roles: [],
      role: '',
      roleForm: {
        roleName: '',
        description: '',
        companyId: 0,
      },
    }
  },
  computed: {
    gridHeight() {
      return window.innerHeight / 1.3
    },
  },
  created() {
    if (this.$route.query.companyId) {
      this.companyId = this.$route.query.companyId
    } else {
      this.companyId = 0
    }
    this.getRolesList()
  },
  methods: {
    createRole(e) {
      const result = e.validationGroup.validate()
      if (result.isValid) {
        this.roleForm.companyId = this.companyId
        userRoleService.createRole(this.roleForm).then(() => {
          this.getRolesList()
        })
      }
    },
    onClickPermissions(data) {
      this.$router.push({ name: 'route-management-role-permissions', params: { roleId: data.id, roleName: data.roleName } })
    },
    getRolesList() {
      this.roles.splice(0, this.roles.length)
      userRoleService.getUserSubRoles(this.companyId).then(result => {
        const { data } = result
        this.roles = data
      })
    },
    createRoleName() {
      this.role.companyId = this.companyId
      userRoleService.createRole(this.role).then(() => {
        this.getRolesList()
      })
    },
  },
}
</script>

<style lang="scss"></style>
